.dot {
  background: var(--color-accent);
  border-radius: 100px;
  display: inline-block;
  height: 1em;
  margin-right: 0.3em;
  vertical-align: bottom;
  width: 1em;
}

.dot + .dot {
  margin-left: -0.5em;
}

.dot.is-small {
  font-size: 0.65em;
  vertical-align: baseline;
}
