.navbar {
  position: fixed;
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-bg-card);
  color: var(--color-fg);
  z-index: 1000;
  transform: translateZ(100px);
  opacity: 0.9;
  box-shadow: 0 0 15px #0003;
  font-size: 1.4em;
}

.navbar.is-result {
  background: var(--color-accent);
  color: var(--color-accent-contrast);
}
