.suggestions {
  position: relative;
  height: 250px;
  max-height: 25vh;
  padding-bottom: 30px;
}

.suggestions > div {
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
}

.suggestions::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(to top, var(--color-bg-card), transparent);
}