.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11000;
  overflow: auto;
  transform: translateZ(1000px);
  display: grid;
  align-items: center;
  justify-content: center;
  background: #000c;
  padding: 0.5em;
  animation: modalEnter ease-out forwards 400ms;
  transform: translateY(200%);
  backdrop-filter: blur(5px);
}

.modal.is-closing {
  animation: modalExit ease-in forwards 400ms;
}

.modal__close {
  position: fixed;
  top: 10px;
  right: 10px;
  background: var(--color-cta);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11001;
}

.modal__close::before,
.modal__close::after {
  content: '';
  height: 0;
  width: 14px;
  border: 2px solid var(--color-cta-contrast);
  transform: translate(-50%, 7px) rotate(45deg);
  transform-origin: right bottom;
  display: block;
  position: absolute;
}

.modal__close::after {
  transform: translate(50%, 7px) rotate(-45deg);
  transform-origin: left bottom;
}

@keyframes modalEnter {
  0% { transform: translateY(200%); }
  100% { transform: translateY(0%); }
}

@keyframes modalExit {
  0% { transform: translateY(0%); }
  100% { transform: translateY(200%); }
}