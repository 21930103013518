.logo {
  height: 140px;
  width: 140px;
  /* animation: 1s ease-out 0s 1 enter; */
  position: relative;
  z-index: 1000;
}

@keyframes enter {
  0% {
    transform: scale(100);
  }
  100% {
    transform: scale(1);
  }
}
