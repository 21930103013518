.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.actions__button {
  padding: 0.7em;
  font-size: 0.7em;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 1.8em;
}

.actions__button svg {
  height: 2.2em;
  width: 2.2em;
}

.actions__button.is-active {
  color: var(--color-accent);
}
