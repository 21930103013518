.status {
  display: flex;
  justify-content: center;
  margin-left: 0.4em;
}

.status__toggle {
  margin: 0.2em;
  padding: 0.2em 0.5em;
  opacity: 0.5;
}

.use-compact .status__toggle {
  padding: 0.1em 0.2em;
}

.status__toggle.is-active {
  opacity: 1;
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
}
