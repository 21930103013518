.option {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.3em 0;
  accent-color: var(--color-accent);
}

.option--inline {
  display: inline-flex;
  margin-right: 0.5em;
}

.option__flag {
  margin-left: 0.5em;
  opacity: 0.6;
}

.option input.is-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
}

.option small {
  opacity: 0.5;
  margin-left: 0.5em;
}
