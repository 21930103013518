.login-form.is-loading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 100%;
  background: var(--color-accent);
  transform-origin: left center;
  animation: load 2000ms infinite linear;
  z-index: 1;
}

.login-form.is-loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.75;
}

.login-form__label {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.login-form__error {
  color: var(--color-accent);
  margin-bottom: 10px;
}

@keyframes load {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0) translateX(100%);
  }
}
