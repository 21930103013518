.statistics__table {
  width: 100%;
  text-align: right;
  line-height: 1.5em;
  overflow: hidden;
}

.statistics__table th,
.statistics__table td {
  padding: 3px 0;
}

.statistics__table small {
  opacity: 0.6;
}

.statistics__table th {
  text-align: left;
  padding-right: 10px;
}

.statistics__table tr.has-progress {
  position: relative;
  overflow: hidden;
}

.statistics__table tr.has-progress td,
.statistics__table tr.has-progress th {
  padding-bottom: 10px;
}

.statistics__table tr.has-progress .statistics__value::before,
.statistics__table tr.has-progress .statistics__value::after {
  content: "";
  background: var(--color-accent);
  position: absolute;
  height: 4px;
  width: 100%;
  left: 0;
  bottom: 5px;
  transform-origin: left;
}

.statistics__table tr.has-progress .statistics__value::before {
  opacity: 0.5;
}
.statistics__table tr.has-progress .statistics__value::after {
  transform: scaleX(var(--val));
}

.statistics__table tr th.has-next,
.statistics__table tr th.has-prev {
  padding-left: 16px;
}

.statistics__table tr th.has-next::after {
  content: "";
  width: 2px;
  height: 100%;
  background-color: currentColor;
  position: absolute;
  top: 16px;
  left: 4px;
}

.statistics__table tr th.has-next::before,
.statistics__table tr th.has-prev::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: currentColor;
  position: absolute;
  top: 12px;
  left: 0px;
  border-radius: 50%;
}

.statistics__table input {
  margin-right: 10px;
}

.statistics__bar {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.statistics__bar::after {
  width: 100%;
  content: "";
  height: 10px;
  margin-left: 10px;
  display: block;
  background-color: var(--color-accent);
  transform-origin: left center;
  transform: scaleX(var(--val, 1));
}

.statistics__value {
  color: var(--color-accent);
}
