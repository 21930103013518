.filters {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color-bg-card);
  color: var(--color-fg);
  z-index: 1000;
  transform: translateZ(100px);
  opacity: 0.9;
  box-shadow: 0 0 15px #0003;
}

.filters__element {
  background: var(--color-accent);
  color: var(--color-accent-contrast);
  display: inline-block;
  padding: 1px 20px 3px 5px;
  border-radius: 100px;
  margin: 10px 5px;
  position: relative;
  cursor: pointer;
}

.filters__element::after,
.filters__element::before {
  content: "";
  width: 0.6em;
  height: 2px;
  background: currentColor;
  position: absolute;
  top: 50%;
  right: 0.3em;
  transform: translateY(-1px) rotate(var(--deg, 45deg));
}

.filters__element::before {
  --deg: -45deg;
}
